module.exports = {
    driverOptions: {
        billingMode: {
            label: '计费模式',
            type: 'radio-group',
            name: 'billingMode',
            radios: [
                {
                    "model-value": '',
                    "label": '包年/包月',
                },
                {
                    "model-value": '',
                    "label": '按需计费'
                }
            ]
        },
        clusterName: {
            label: '集群名称',
            type: "input",
            name: "clusterName",
            placeholder: '',
            rules: [{ required: true, message: '请填写集群名' },
            { pattern: /^[a-z][a-z0-9-]{0,54}[a-z0-9]$|^[a-z]$/gm, message: '以小写字母开头,由小写字母、数字、中划线(-)组成,长度范围1-56位,且不能以中划线(-)结尾' }],
        },
        eipAddress: {
            label: 'eipAddress',
            type: "input",
            name: "eipAddress",
            placeholder: '',
            rules: [],
        },
        KubernetesVersion: {
            label: '版本',
            type: "radio-group",
            name: "KubernetesVersion",
            placeholder: '',
            radios: [
                {
                    "model-value": '',
                    "label": 'v1.19',
                },
                {
                    "model-value": '',
                    "label": 'v1.21'
                }
            ],
            rules: [{ required: true, message: '请填写Kubernetes版本' }],
        },
        clusterSpec: {
            label: '集群管理规模',
            type: "select",
            name: "clusterSpec",
            placeholder: '请选择集群管理规模',
            rules: [{ required: true, message: '请选择集群管理规模' }],
        },
        clusterType: {
            label: '集群类型',
            type: "select",
            name: "clusterType",
            placeholder: '请选择集群类型',
            rules: [{ required: true, message: '请选择集群类型' }],
        },
        projectId: {
            label: '企业projectId',
            type: "input",
            name: "projectId",
            placeholder: '',
            rules: [{ required: true, message: '请填写企业projectId' }],
        },
        regionId: {
            label: '区域',
            type: "select",
            name: "regionId",
            placeholder: '请选择区域',
            rules: [{ required: true, message: '请选择区域' }],
        },
        vpcId: {
            label: 'vpcId',
            type: "input",
            name: "vpcId",
            placeholder: '',
            rules: [{ required: true, message: '请填写vpcId' }],
        },
        // vpcId: {
        //     label: '',
        //     type: "radio-group",
        //     name: "vpcId",
        //     placeholder: '',
        //     radios: [
        //         {
        //             "model-value": '',
        //             "label": 'VPC网络',
        //         },
        //         {
        //             "model-value": '',
        //             "label": '容器隧道网络'
        //         }
        //     ],
        //     rules: [{ required: true, message: '请填写vpcId' }],
        // },
        subnetId: {
            label: '子网',
            type: "select",
            name: "subnetId",
            placeholder: '请选择子网',
            rules: [{ required: true, message: '请选择子网' }],
        },
    },
    nodesOptions://为方便取每个节点格式 只取其一个元素所需的对象
    {
        cardType: {
            formType: "radio-group",
            label: 'cardType',
            name: 'cardType',
            rules: [{ required: true, message: '请填写cardType', trigger: 'blur' }],
            type: 'text',
        },

        nodePoolName: {
            label: 'nodePoolName',
            type: "nodePoolName",
            formType: "input",
            name: "nodePoolName",
            placeholder: '',
            rules: [{ required: true, message: '请填写nodePoolName' }, {
                pattern: /^[a-z][a-z0-9-]{0,54}[a-z0-9]$|^[a-z]$/gm,
                message: '以小写字母开头,由小写字母、数字、中划线(-)组成,长度范围1-56位,且不能以中划线(-)结尾。'
            }],
            display: true,
        },
        nodeNums: {
            label: 'nodeNums',
            type: "number",
            formType: "input",
            name: "nodeNums",
            placeholder: '',
            rules: [{ required: true, message: '请填写nodeNums' },
            { pattern: /^[1-9]\d*$/gm, message: '节点数至少为1' }
                // {type:'number',min:2,message:'节点数至少为1'}
            ],
            display: true,
        },

        dataDiskSize: {
            label: 'dataDiskSize',
            type: "dataDiskSize",
            formType: "input",
            name: "dataDiskSize",
            placeholder: '',
            rules: [{ required: true, message: '请填写dataDiskSize' }],
            display: true,
        },

        nodeAz: {
            label: 'nodeAz',
            type: "nodeAz",
            formType: "select",
            name: "nodeAz",
            placeholder: '',
            rules: [{ required: true, message: '请填写nodeAz' }],
            display: true,
        },

        nodeTypes: {
            formFlag: true,
            title: 'nodeTypes',
            label: 'nodeTypes',
            type: "nodeTypes",
            formType: "checkbox-group",
            name: "nodeTypes",
            placeholder: '请选择nodeTypes',
            rules: [{ required: true, message: '请填写nodeTypes' }],
            display: true,
            // rules: []
        },
        nodeLabels: {
            label: 'nodeLabels',
            formFlag: true,
            title: 'nodeLabels',
            formType: 'inlineInput',
            name: 'nodeLabels',
            key: {
                label: '',
                type: "nodeLabelsKey",
                name: "nodeLabelsKey",
                placeholder: 'key',
                // rules: [{ required: true, message: '请填写key' }],
                rules: []
            },
            value: {
                label: '',
                type: "nodeLabelsValue",
                name: "nodeLabelsValue",
                placeholder: 'value',
                // rules: [{ required: true, message: '请填写value' }],
                rules: []
            },
            display: true,
        },
        resourceTags: {
            formFlag: true,
            label: 'resourceTags',
            title: 'resourceTags',
            name: 'resourceTags',
            formType: 'inlineInput',
            key: {
                label: '',
                type: "resourceTagsKey",
                name: "resourceTagsKey",
                placeholder: 'key',
                // rules: [{ required: true, message: '请填写key' }],
                rules: []
            },
            value: {
                label: '',
                type: "resourceTagsValue",
                name: "resourceTagsValue",
                placeholder: 'value',
                // rules: [{ required: true, message: '请填写value' }],
                rules: []
            },
            display: true,
        },
    },
    nodesCommonOptions: {
        cloud: {
            label: 'cloud',
            type: "cloud",
            formType: "radio-group",
            name: "cloud",
            placeholder: '',
            rules: [{ required: true, message: '请填写cloud' }],
            display: true,
        },
        loginTypes: {
            label: 'loginTypes',
            formType: "radio-group",
            name: "loginTypes",
            rules: [{ required: true, message: '请填写loginTypes' }],
            display: true,
        },
        sshKeyName: {
            label: 'sshKeyName',
            type: "sshKeyName",
            formType: "input",
            name: "sshKeyName",
            placeholder: '',
            rules: [{ required: true, message: '请填写sshKeyName' }],
            display: true,
        },
        loginPassWord: {
            label: 'loginPassword',
            type: "password",
            formType: "input",
            name: "loginPassWord",
            placeholder: '',
            rules: [{ required: true, message: 'loginPassWord' }],
            display: true,
        },
        loginUserName: {
            label: 'loginUserName',
            type: "loginUserName",
            formType: "input",
            name: "loginUserName",
            placeholder: '',
            rules: [{ required: true, message: '请填写loginUserName' }],
            display: true,
        },
    },
    accessData: {
        accessKeyID: {
            label: '',
            type: "text",
            name: "accessKeyID",
            placeholder: '',
            rules: [{ required: true, message: '请填写accessKeyID', trigger: 'change', }],
        },
        accessKeySecret: {
            label: '',
            type: "password",
            name: "accessKeySecret",
            placeholder: '',
            rules: [{ required: true, message: '请填写accessKeySecret', trigger: 'change', }],
        },
    },
}