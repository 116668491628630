<template>
  <div id="layer">
    <el-row justify="space-between">
      <el-col :span="6">
        <div @click="$emit('prev-click')" class="prev">
          <van-icon name="arrow-left" class="huawei-btn huawei-prev-btn" />
          <el-button type="text" class="huawei-text huawei-prev-txt"
            >上一步</el-button
          >
        </div>
      </el-col>

      <el-col :span="12"></el-col>

      <el-col :span="6">
        <div @click="$emit('next-click')" class="next">
          <el-button :type="$store.getters.processActive===5?'primary':'text'" :plain="$store.getters.processActive===5?true:false" class="huawei-text huawei-next-txt"
            >{{$store.getters.processActive===5?"创建集群":"下一步"}}</el-button
          >
          <van-icon name="arrow" class="huawei-btn huawei-next-btn" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: [
    "prevPath", // 后退的路由
    "nextPath", // 前进的路由
  ],
};
</script>

<style lang="less">
@sideWidth: 0.86rem;

.huawei-btn {
  color: #409eff;
  // display: inline-block;
}
// .huawei-text {
//   display: inline-block;
// }
.prev {
  // margin-left: @sideWidth;
}
.next {
  // align-self: flex-end;
}

#layer {
  position:fixed;
  padding: 10px;
  bottom:0px;
  left:12vw;
  right:0px;
  background-color: white;
  z-index: 1;
  height: 50px;
}
</style>