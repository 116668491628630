module.exports = {
  PROCESS_ACTIVE: {
    CHANNEL: 0,
    ACCESS: 1,
    CLUSTER: 2,
    NODE: 3,
    APPCONFIG: 4,
    COMPCONFIG:5,
    CONSTRUCT: 6,
  },
  DEFUALT_INPUT: {
    eipAddress: '',
    KubernetesProxyMode: 'ipvs',
    containerNetWorkMode: "vpc-router",
    clusterSpecPlatform: "VirtualMachine",
    USER: 'admin',
    DMETASOUL_INGRESS_ENABLED: true,
    DMETASOUL_NAMESPACE: 'dmetasoul',
    DMETASOUL_REPO_PREFIX: 'swr.cn-southwest-2.myhuaweicloud.com/dmetasoul-public',
    DMETASOUL_NAMESPACE:"dmetasoul",
    DMETASOUL_SERVICEACCOUNT:"dmetasoul",
  },
  INFORM_MESSAGE: {
    RDS: "如果需要初始化hive库，请补充信息。",
    DELETE_CLUSTER_INFORM: "本操作属于系统集群信息管理行为，如要删除集群本体，请前往相应云平台手动删除。",
  },
  CREATE_STAGE_INFO:[
    {
      stage: 0,
      stageDescription: "集群开始创建",
      processStep:"CREATE_START",
    },
    {
      stage: 10,
      stageDescription: "创建默认节点",
      processStep:"CREATE_DEFAULT_NODE",
    },
    {
      stage: 30,
      stageDescription: "配置默认节点池",
      processStep:"DEPOLOY_NODEPOOL",
    },
    {
      stage: 50,
      stageDescription: "配置扩缩容节点池",
      processStep:"DEPOLOY_DEFAULTPOOL",
    },
    {
      stage: 50,
      stageDescription: "其他节点池配置",
      processStep:"DEPOLOY_OTHERPOOL",
    },
    {
      stage: 80,
      stageDescription: "集群创建完成",
      processStep:"CLUSTER_CREATED",
    },
    {
      stage: 90,
      stageDescription: "集群初始化",
      processStep:"CLUSTER_INIT",
    },
    {
      stage: 95,
      stageDescription: "集群应用部署",
      processStep:"CLUSTER_APPLICATION",
    },
    {
      stage: 100,
      stageDescription: "部署完成",
      processStep:"CLUSTER_COMPLETE",
    }
  ]
}
